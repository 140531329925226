<template>
    <ul
        v-if="data"
        class="list-inline"
    >
        <li
            v-for="(label, index) in data"
            :key="`label-${index}`"
            class="list-inline-item"
        >
            <base-icon
                v-if="label.icon"
                :name="label.icon"
                :classes="(label.variant !== 'muted') ? label.variant : ''"
            />
            <span
                :class="[
                    `label label-${label.variant}`,
                    {[`label-diamond label-diamond-${label.variant}`]:
                        !label.icon && label.variant !== 'muted'
                    }
                ]"
                v-html="label.text"
            />
        </li>
    </ul>
</template>

<script>
export default {
    name: 'BaseListLabels',

    props: {
        data: {
            required: true,
            validator: (value) => ['undefined', 'boolean'].includes(typeof value) || Array.isArray(value),
        },
    },
};
</script>
